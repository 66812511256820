@import 'variables';

.contentful {
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 28px 0 20px;
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 16px 0 20px;
  }

  h3, h4, h5, h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 20px;
  }

  blockquote {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;

    & > * {
      font-size: inherit;
      line-height: inherit;
    }
  }

  ul {
    padding-left: 20px;
    margin-bottom: 24px;

    li {
      padding-left: 12px;
      list-style: disc;

      &::marker {
        color: rgba($primary-dark, 0.24);
      }
    }
  }

  ol {
    padding-left: 16px;
    margin-bottom: 24px;

    li {
      padding-left: 16px;
      list-style: decimal;
    }
  }

  hr {
    margin: 32px 0;
    color: rgba($black, 0.12);
  }

  a {
    color: $primary;
  }

  img {
    width: 100%;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  video {
    width: 100%;
    margin-bottom: 24px;
    border-radius: 12px;
  }

  .attachment {
    display: flex;
    align-items: center;
    background-color: $background;
    border-radius: 12px;
    padding: 4px;
    position: relative;
    margin-bottom: 40px;

    .attachment-icon {
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 12px;

      svg {
        width: 20px;
        height: 20px;
        color: $primary;
      }
    }

    .filename {
      color: $primary;
      flex: 1;
      min-width: 1px;
      font-weight: 600;
    }
  }
}
