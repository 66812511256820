@import 'node_modules/@incendi-io/theme/src/theme-variables';

$browser-context: 14;
$card-padding: 20px;
$button-lg-size: 48px;
$form-control-height: 48px;
$content-padding: 48px;
$button-border-radius: 12px;
$sidenav-width: 200px;
$main-content-header-height: 105px;
$content-gutter: 50px;

// Colors
$dark-gray: #636466;
$light-gray: #8b929a;
$white: #fff;
$whisper: #e5e5e5;
$primary: #447ac3;
$default-gray: #848d9d;
$primary-dark: #1f2633;
$black: #151a1f;
$red: #eb5e42;
$border-color: #f1f1f2;
$list-bg: #f4f5f7;
$background: #f5f7fa;
$gainsboro: #dedede;
$user-bg: #ecf2f9;
$active-color: #eba154;
$transfering-color: #f0ce57;
$logo-bg: #2e60a3;
$received-color: #49b2cc;
$given-color: #eb6e55;
$divider-color: #b7b9bd;

// Table
$table-header-text: #6c7787;
$table-header-td: $light-gray;
$table-hover-bg: transparentize($logo-bg, .97);

// main
$main-font: 'Nunito', sans-serif;
$title-color: $dark-gray;
$header-height: 72px;
$blue: #009dbc;
$violet: #524b85;




