@import 'node_modules/@incendi-io/theme/src/theme-variables';
@import '../styles/mixins';

.loader_main {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: rem(5);
  position: relative;
  text-indent: -9999em;
  border-top: .2rem solid rgba(80, 124, 245, 0.2);
  border-right: .2rem solid rgba(80, 124, 245, 0.2);
  border-bottom: .2rem solid rgba(80, 124, 245, 0.2);
  border-left: .2rem solid $color-primary;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
