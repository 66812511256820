@use 'sass:math';

@import '~@incendi-io/theme/src/theme';
@import 'node_modules/bootstrap/scss/mixins';
@import 'app/styles/material';
@import 'app/styles/variables';
@import 'app/styles/mixins';
@import 'app/styles/loader';
@import 'app/styles/contentful';

html {
  height: 100%;
  font-size: #{$browser-context}px;
}

body {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  font-family: $main-font;
}

.mt-6 {
  margin-top: 4.5rem;
}

.mt-7 {
  margin-top: 6rem;
}

ul, ol, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dot-divider {
  color: #ccced0;
  padding: 0 12px;
}

.container {
  @include make-container($content-gutter);
  @include make-container-max-widths($content-gutter);
}

.container-fluid {
  @include make-container($content-gutter);
}

.container-row {
  @include make-row($content-gutter);
}


label {
  .inline {
    margin-top: 2rem !important;
  }
}

.small-watermark {
  color: $default-gray;
  font-weight: 500;
  font-size: rem(12);
}

textarea.form-control {
  resize: none;
}

textarea.mat-input-element {
  box-sizing: content-box;
  padding: 0;
  margin: 0;
}

.load-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

select {
  margin-top: 0.5rem !important
}

.ngb-highlight {
  font-weight: 500;
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content-header {
  padding: 1rem 1.5rem 0 1.5rem;
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  .main-content-header-mid {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .date-period {
      font-weight: $font-weight-semibold;
    }
  }

  .main-content-header-rs {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .date-period {
    background-color: $sidenav-color;
    border-radius: 43px;
    display: flex;
    margin-left: 1rem;
    padding: 1.2rem;
    justify-content: space-between;
    min-width: 300px;
  }

}

.main-content-body {
  flex: auto;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.side-nav {
  background-color: white;
  flex: 0 0 $sidenav-width;
}

.side_menu {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: $nav-padding;
  cursor: pointer;

  .side-menu-link {
    color: $primary-dark;
    font-weight: 600;
    height: 48px;
    display: inline-flex;
    align-items: center;
  }

  .side-menu-link:hover {
    color: $active-color;
  }

  .side-menu-link.side-menu-active {
    color: $active-color;
  }
}


.divider {
  border-bottom: 1px solid $color-input-border;
}

.nav-tabs {
  padding: 1rem 0;
  font-weight: $font-weight-semibold;

  .nav-link.nav-item {
    padding: 1rem 0;
    color: $color-text-light;
    margin-left: 3rem;
  }

  .nav-link.nav-item:first-child {
    padding-left: 0;
    margin-left: 0;
  }

  .nav-link.nav-item.active {
    color: $color-primary;
    border-bottom: 2px solid $color-primary;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

h4, h5, h6 {
  font-weight: $font-weight-semibold;
}

.btn-link {
  padding: 0;
}

.footer-between {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}


.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: $color-lightgray;
  border-color: $color-lightgray;
}

.b-menu-content {
  padding: 1rem;
  color: $color-primary;
  font-weight: $font-weight-semibold;
  cursor: pointer;
}

:focus {
  outline: none;
}

textarea.form-control {
  margin-top: 0.5rem !important;
}

.b-menu-content {
  background: white;
}

.round_icon {
  margin-left: 1rem;
  cursor: pointer;
  background-color: $sidenav-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.round_icon.close {
  background-color: $color-primary;
}

.btn-lg {
  min-width: 9rem;
}

.pre-load-modal {
  position: absolute;
  background-color: white;
  opacity: .9;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.b-resource-role {
  width: 140px;
  white-space: pre-wrap;
}

.ngb-tp-input-container {
  width: 5em !important;
}

@mixin btn-icon-base {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;

  &:not(.btn-primary) {
    color: rgba($color-text-dark, 0.72);
    background-color: $color-input-bg;
  }
}

@mixin btn-icon-no-bg-base {
  @include btn-icon-base;

  width: 2rem;
  height: 2rem;
  padding: 0;
  background-color: transparent !important;
}

.btn-icon {
  @include btn-icon-base;

  img,
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.btn-icon-sm {
  @include btn-icon-base;

  img,
  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.btn-icon-no-bg {
  @include btn-icon-no-bg-base;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.btn-icon-no-bg-sm {
  @include btn-icon-no-bg-base;

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.btn-icon-no-bg-md {
  @include btn-icon-no-bg-base;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.count-badge {
  color: $color-text-light;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid $color-input-border;
  border-radius: 1rem;
  margin-left: 1rem;
  line-height: 2rem;
}

.profile_menu {
  display: flex;
  flex-direction: column;

  a {
    color: $color-text-light;
    font-size: 1rem;
    padding: .5rem;
    cursor: pointer;
  }

  a:hover {
    color: $color-primary;
  }

  a.main_menu--active {
    color: $color-primary;
    font-weight: $font-weight-semibold;
  }
}

.ar-4_3 {
  @include aspect-ratio-block(math.div(3, 4));
}

.ar-16_9 {
  @include aspect-ratio-block(math.div(9, 16));
}

.spinner-border {
  width: 32px;
  height: 32px;
  border-width: 2px;
  border-right: 2px solid $active-color;
  color: transparentize($active-color, .9);
}

iframe {
  border-width: 0 !important;
}

.your-trust-card {
  background: $white;
  border-radius: 16px;
}

.text-invalid {
  color: $red !important;
}

.text-grey {
  color: rgba($primary-dark, 0.52);
}

.form-control-switch {
  $size: 28px;
  $inner-padding: 3px;
  .switch {
    transform: scaleX(-1);
    background: transparentize($default-gray, 1 - .35);
    border: none;
    width: 52px;
    &.checked {
      background: $primary;
    }
    &.switch-medium {
      outline: none;
      height: $size;
      border-radius: $size;
      small {
        box-shadow: 3px 0 4px rgba(0, 0, 0, 0.25);
        width: $size - $inner-padding * 2;
        height: $size - $inner-padding * 2;
        left: $inner-padding;
        top: $inner-padding;
      }
    }
    &.checked small {
      right: $inner-padding;
      left: auto;
      box-shadow: -3px 0 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.form-control-wrapper {
  position: relative;
  .control-messages {
    position: absolute;
    bottom: rem(12);
    right: 26px;
    color: $red;
    font-weight: 500;
    font-size: rem(12);
    background: $list-bg;
    line-height: 2;
    padding-left: 5px;
  }
  app-drop-down + .control-messages {
    right: 48px;
    background: transparent;
  }
}

.form-control {
  height: $form-control-height;
  border-radius: math.div($form-control-height, 2);
  border: 1.5px solid transparent;
  outline: none;
  padding: 0.5rem math.div($form-control-height, 2);
  font-size: rem(14);
  line-height: rem(24);
  font-weight: 600;
  background-image: none !important;
  color: $primary-dark;
  &::-webkit-input-placeholder {
    font-weight: 600;
    font-size: rem(14);
    color: rgba($primary-dark, 0.32);
  }
  &::-moz-placeholder {
    font-weight: 600;
    font-size: rem(14);
    color: rgba($primary-dark, 0.32);
  }
  &:-moz-placeholder {
    font-weight: 600;
    font-size: rem(14);
    color: rgba($primary-dark, 0.32);
  }
  &:-ms-input-placeholder {
    font-weight: 600;
    font-size: rem(14);
    color: rgba($primary-dark, 0.32);
  }
  &:focus {
    background: $white;
    box-shadow: none !important;
    border: 1.5px solid $default-gray;
    + .control-messages {
      background: $white;
    }
  }
  &.ng-invalid.ng-touched {
    border: 1.5px solid $red;
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  box-shadow: none !important;
  border: 1.5px solid $red;
  background-image: none;
}

.form-control-label {
  display: inline-block;
  position: relative;
  top: rem(3);
  padding-left: 24px;
  font-size: 1rem;
  font-weight: 500;
  line-height: rem(21);
  color: $default-gray;
}

.button-drop-down {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.button-drop-down-menu.dropdown-menu {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: none;
  padding: 8px;

  .dropdown-item {
    display: flex;
    align-items: center;
    width: 224px;
    color: $primary-dark;
    font-weight: 600;
    cursor: pointer;
    outline: none !important;
    padding: 10px 16px;
    border-radius: 4px;

    &:hover {
      background: $table-hover-bg;
    }

    svg {
      margin-left: auto;
    }
  }
}

.form-control-drop-down {
  width: 100%;
  height: $form-control-height;
  position: relative;

  .dropdown-toggle {
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: math.div($form-control-height, 2);
    background: $list-bg;
    color: $default-gray;
    font-weight: 600;
    text-align: start;
    padding: 0 24px;
    border: 1.5px solid transparent;
    &::after {
      display: none;
    }
    &:focus, &.focus {
      box-shadow: none;
    }
  }

  &.show > .dropdown-toggle, .dropdown-toggle:not(:disabled):not(.disabled):active {
    background-color: $white !important;
    border-color: $default-gray !important;
    color: $default-gray !important;
    box-shadow: none !important;
    border: 1.5px solid $default-gray;
    position: absolute;
    z-index: 1001;
  }

  svg {
    margin-left: auto;
    width: 14px;
    height: 14px;
    &.expanded {
      transform: rotate(180deg);
    }
  }
  .dropdown-menu {
    width: 100%;
    position: relative;
    margin-top: math.div(-$form-control-height, 2);
    padding-top: math.div($form-control-height, 2) + 6px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border: none;
    .inner-list {
      max-height: 200px;
      position: relative;
    }
    .dropdown-item {
      color: $primary-dark;
      font-weight: 600;
      padding: 3px 30px;
      opacity: .5;
      cursor: pointer;
      &.drop-down-item-active {
        color: $primary-dark;
        opacity: 1;
      }
    }
  }
}

.btn-lg {
  height: $button-lg-size;
  border-radius: 12px;
  font-size: rem(14);
  font-weight: 600;
  border: none;
  transition: background-color .2s ease-out;
  padding: 0 15px;
  &.btn-active {
    background-color: $active-color;
    color: $white;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      box-shadow: none;
      background-color: darken($active-color, 10);
    }
  }
  &.btn-info {
    background-color: rgba($primary, 0.1);
    color: $primary;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      color: $primary;
      box-shadow: none;
      background-color: darken(rgba($primary, 0.1), 10);
    }
    &:disabled,
    &[disabled] {
      &.loading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 15px;
        .spinner-border {
          border-right: 2px solid $primary;
          color: transparentize($primary, .9);
        }
      }
    }
  }
  &.btn-primary {
    background-color: $primary;
    color: $white;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      box-shadow: none;
      background-color: darken($primary, 10);
    }
  }
  &.btn-danger {
    background-color: $red;
    color: $white;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      box-shadow: none;
      background-color: darken($red, 10);
    }
  }
  &.btn-red {
    background-color: transparentize($red, .9);
    color: $red;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      box-shadow: none;
      background-color: transparentize(darken($red, 10), .9);
    }
  }
  &.btn-active {
    background-color: $active-color;
    color: $white;
    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &.focus, &:not(:disabled):not(.disabled):active:focus
    {
      box-shadow: none;
      background-color: darken($active-color, 10);
    }
    &:disabled,
    &[disabled] {
      background: transparentize($active-color, .9);
      color: $active-color;
      &.loading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 15px;
      }
    }
  }
}

.modal-footer-button {
  min-width: 172px;
}

.modal-backdrop.show {
  opacity: 0.8;
  background-color: $primary-dark;
}

.modal-backdrop.fade {
  transition: none;
}

.your-trust-details-modal {

  .modal-content {
    background-color: #fff;
    border-radius: 16px;
  }

  .modal-body {
    padding: 0 32px;
  }

  .modal-footer {
    border: none;
    padding: 32px;
  }

  .pre-load-modal {
    top: 72px;
    bottom: 0;
    height: initial;
    border-radius: 16px;
  }

  @media (min-width: 676px) {
    .modal-dialog {
      max-width: 660px;
    }
  }
}

.sidenav-content-wrapper {
  padding: 0 $content-padding;
}

.mat-snack-bar-container {
  background: $primary-dark;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 12px !important;
  color: transparentize($white, .08);

  &.container-flexible {
    max-width: 70vw;
    min-width: 0;

    svg {
      margin-left: 20px!important;
    }
  }
}

.mat-autocomplete-panel {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background: $white;
}

.mat-form-field {
  .mat-select.mat-select-invalid .mat-select-arrow {
    color: transparentize($primary-dark, .46);
  }
  &.mat-focused {
    .mat-form-field-label {
      color: $primary;
    }
    .mat-form-field-ripple {
      background-color: $primary;
    }
  }
  .mat-form-field-infix {
    padding: 1.15em 0;
  }

  input.mat-input-element {
    transform: translateY(-1px);
    font-weight: 600;
  }

  .mat-form-field-label {
    top: rem(26);
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.84373em) scale(0.9);
    width: 133.3333533333%;
  }
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  height: 1px;
  background-color: transparentize($black, .88);
}

.mat-form-field-ripple {
  background-color: transparentize($black, .6);
}

.mat-form-field-label {
  color: transparentize($primary-dark, .48);
  font-weight: 700 !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: $red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $red;
}

.mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url(assets/icons/drop-down-icon.svg);
  border-image-repeat: stretch !important;
  margin: 0 !important;
}

.mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}


.add-staff-button {
  width: 170px;
  svg {
    width: 11px;
    height: 11px;
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
}

.pagination {
  .page-link {
    border: none;
    font-size: rem(13);
    font-weight: 600;
    color: transparentize($primary-dark, .6);
    width: 30px;
    height: 30px;
    margin: 0;
    &:before {
      display: none;
    }
    svg {
      width: 10px;
      height: 10px;
      min-width: 10px;
      margin-left: 8px;
    }
  }
  .page-item.active .page-link {
    background-color: transparent;
    border: none;
    color: $active-color;
    white-space: nowrap;
  }
  .page-item {
    &:first-child {
      margin-right: 45px;
      .page-link {
        color: $active-color;
        white-space: nowrap;
        svg {
          transform: rotate(180deg);
          margin-right: 8px;
          margin-left: 0;
        }
      }
      &.disabled {
        opacity: 0;
        margin-left: -38px;
      }
    }
    &:last-child {
      margin-left: 45px;
      .page-link {
        color: $active-color;
      }
      &.disabled {
        opacity: 0;
        margin-right: -38px;
      }
    }
  }
}

ui-switch {
  .switch {
    box-shadow: none;

    &.switch-medium small {
      width: 28px;
      height: 28px;
      right: calc(100% - 29px);
    }

    &.checked small {
      right: 1px;
      left: auto;
    }

    small {
      box-shadow: none;
      top: 1px;
    }
  }

  button:focus {
    outline: none;
  }
}

// Fix zendesk snippet margin
body > div iframe#launcher {
  margin-right: 25px !important;
}
