@use 'sass:math';

@import 'variables';

@mixin aspect-ratio-block($ar) {
  width: 100%;
  padding-bottom: $ar * 100%;
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin vertical-ellipsis($rows) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
  overflow: hidden;
  word-break: break-all;
}

@mixin drop-down-last-item {
  padding: 20px 16px 10px;
  position: relative;
  &:hover {
    $top-point: 21%;
    $bottom-point: 100%;
    background: linear-gradient(180deg, transparent 0%,
      transparent $top-point, $table-hover-bg $top-point,
      $table-hover-bg $bottom-point,
      transparent $bottom-point,
      transparent 100%);
  }
  &:after {
    content: '';
    top: 5px;
    left: 16px;
    right: 16px;
    position: absolute;
    height: 1px;
    background: rgba(21, 26, 31, 0.06);
  }
}

@mixin side-panel {
  flex: 0 0 33%;
  position: relative;
  color: $white;
  background-image: url('../../assets/icons/onboarding-bg.svg'), linear-gradient(130deg, #54a0eb 0%, #2c4b7a 100%);
  background-position: 0 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;

  header {
    position: absolute;
    top: 50px;
    left: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;

    svg {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }
  }

  p {
    font-size: 24px;
    line-height: 36px;
  }

  .app-name {
    font-weight: 800;
    font-size: 49px;
    line-height: 67px;
    margin-bottom: 25px;
  }
}

@mixin badge-icon {
  width: 20px;
  height: 20px;
  border: 4px solid #fff;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.icon-receiving {
    background-color: $received-color;
  }

  &.icon-giving {
    background-color: $given-color;
  }

  svg {
    width: 10px;
    height: 10px;
    color: rgba(#fff, 0.8);
  }
}

@mixin price-totals {
  margin-left: 16px;
  font-weight: 600;
  color: $divider-color;

  .received {
    color: $received-color;
  }

  .given {
    color: $given-color;
  }
}

@mixin main-content-wrapper {
  padding: 24px 0;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-shrink: 0;

    h2 {
      font-weight: bold;
      font-size: 24px;
    }

    button {
      font-weight: bold;
      display: flex;
      align-items: center;

      svg {
        width: 11px;
        height: 11px;
        margin-right: 10px;
      }
    }
  }
}

@mixin gift-category {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border-radius: 12px;
  z-index: 1;

  & > svg {
    width: 24px;
    height: 24px;
  }
}

@mixin news-panels {
  h2 {
    font-weight: bold;
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .see-all-link {
    font-weight: bold;
    font-size: 13px;
    color: $active-color;
  }

  .text-grey {
    color: rgba($primary-dark, 0.52);
  }

  .panel {
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
  }

  .announcements-container {
    flex: 0 0 auto;
    margin-bottom: 24px;
  }

  .panel.announcements {
    header {
      margin-bottom: 24px;
    }
  }

  .news-container {
    flex: 1;
    margin-bottom: 32px;
  }

  section.news:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .news {
    overflow: hidden;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .news-cards {
      display: flex;
    }

    .older-news {
      min-width: 1px;

      app-news-card {
        height: 64px;
      }
    }
  }

  app-news-card {
    display: block;
    position: relative;

    &.recent {
      width: 360px;
      height: 240px;
      margin-right: 24px;
      flex-shrink: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    &:not(.recent):not(:last-of-type)::after {
      content: '';
      position: absolute;
      bottom: -12px;
      right: 0;
      left: 112px;
      height: 1px;
      background-color: rgba(21, 26, 31, 0.06);
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

@function rem($pixels, $context: $browser-context) {
  @return #{math.div(strip-unit($pixels), $context)}rem;
}
