@use '~@angular/material' as mat;
@import 'variables';
// Plus imports for other components in your app.

// Define a custom typography config that overrides the font-family
$bribery-typography: mat.define-typography-config($font-family: $main-font);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($bribery-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bribery-palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: $primary,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $primary-dark,
    100: $primary-dark,
    200: $primary-dark,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $primary-dark,
    A200: white,
    A400: white,
    A700: white,
  )
);

$bribery-app-primary: mat.define-palette($bribery-palette);
$bribery-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bribery-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$bribery-app-theme: mat.define-light-theme($bribery-app-primary, $bribery-app-accent, $bribery-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($bribery-app-theme);
@include mat.input-theme($bribery-app-theme);
@include mat.input-typography($bribery-typography);
@include mat.form-field-theme($bribery-app-theme);
@include mat.form-field-typography($bribery-typography);
@include mat.select-theme($bribery-app-theme);
@include mat.select-typography($bribery-typography);
@include mat.option-theme($bribery-app-theme);
@include mat.option-typography($bribery-typography);
@include mat.datepicker-theme($bribery-app-theme);
@include mat.datepicker-typography($bribery-typography);
@include mat.button-theme($bribery-app-theme);
@include mat.button-typography($bribery-typography);
@include mat.progress-spinner-theme($bribery-app-theme);
@include mat.progress-spinner-typography($bribery-typography);

.cdk-overlay-container {
  z-index: 1060;
}
